import { mapState }   from 'vuex'
import countries      from "@/assets/js/countries";
import { stripe_key } from '@/assets/js/utils'

const style = {
  base : {
    color : '#32325d',
    fontFamily : '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing : 'antialiased',
    fontSize : '16px',
    '::placeholder' : {
      color : '#88A4CF'
    }
  },
  invalid : {
    color : '#fa755a',
    iconColor : '#fa755a'
  }
};

const stripe = Stripe ( stripe_key () );

export default {

  data () {
    return {
      cardDetail : {
        name : '',
        streetAddress : '',
        phone : '',
        country : '',
        teamMemberQuantity : 0,
        stripe_plan : 'yearly_plan',
        stripe_product : 'basic',
      },
      paypalPackage : {
        monthly_basic:'monthly_basic',
        monthly_standard:'monthly_standard',
        yearly_basic:'yearly_basic',
        yearly_standard:'yearly_standard'
      },
      stipePackage : {
        basic:'P-1SL96607V4879511DMARDZTQ',
        standard:'P-1SL96607V4879511DMARDZTQ',
      },
      client_secret : null,
      countries : countries,
      cardElement : null,
      errorElementId : null,
      product : null,
    }
  },

  computed : {
    ... mapState ( 'custom', [ 'company_payments_cards' ] ),
    isError () {
      return this.errors.any () ||
        !this.cardDetail.name ||
        !this.cardDetail.phone ||
        !this.cardDetail.streetAddress ||
        !this.cardDetail.country ||
        ( !this.cardDetail.teamMemberQuantity && !this.check_subscription );
    }
  },

  mounted () {
    this.cardElement = stripe.elements ().create ( 'card', { style } );
    this.createSetupIntent ()
  },
  methods : {
    closingModal () {
      this.cardDetail = {
        name : '',
        streetAddress : '',
        phone : '',
        country : '',
        teamMemberQuantity : '',
        stripe_plan : 'monthly_plan'
      };

      this.option =
        {
          plan : null,
          product : null,
          amount : 0
        }
    },
    createSetupIntent () {

      this.$store.dispatch ( 'custom/createSetupIntent', { company_id : this.$route.params.id } )
          .then ( response => {
            this.client_secret = response.data.intent.client_secret
          } )
          .catch ( error => {
            let errorElement = document.getElementById ( this.errorElementId );
            errorElement.textContent = error.response.data.message;
          } )
    },
    onSubmit () {
      if ( this.company_payments_cards.length ) this.cardDetail.teamMemberQuantity = 1;
      this.pay ();
    },
    async pay () {
      const { setupIntent, error } = await stripe.handleCardSetup (
        this.client_secret, this.cardElement, {
          payment_method_data : {
            billing_details : {
              name : this.cardDetail.name,
              phone : this.cardDetail.phone,
              address : {
                country : this.cardDetail.country,
                line1 : this.cardDetail.streetAddress
              }
            }
          }
        }
      );

      if ( error ) {
        // Display "error.message" to the user...
        // Inform the user if there was an error.
        let errorElement = document.getElementById ( this.errorElementId );
        errorElement.textContent = error.message;
        this.$forceUpdate ();
        this.createSetupIntent ()
      } else {
        // The card has been verified successfully...
        let cardDetails = {
          company_id : this.$route.params.id,
          stripe_info : JSON.stringify ( setupIntent ),
          stripe_token : setupIntent.payment_method,
          stripe_plan : this.cardDetail.stripe_plan,
          stripe_product : this.cardDetail.stripe_product,
          quantity : this.cardDetail.teamMemberQuantity,
          default : this.company_payments_cards.length === 0 ? 1 : 0
        };
        this.add_payment_card ( cardDetails );
      }
    },
    add_payment_card ( cardDetails ) {
      this.$store.dispatch ( 'custom/add_payment_card', cardDetails )
          .then ( response => {
            this.$store.commit ( 'custom/toggle_loader', false );
            if ( response.data.error ) {
              let errorElement = document.getElementById ( this.errorElementId );
              errorElement.textContent = response.data.error_msg;
              this.createSetupIntent ()
            } else {
              this.$root.$emit("snack_bar", {'show' : true, 'message' : response.data.error_msg, 'snackbarColor' : 'green'});
              this.$store.dispatch ( 'custom/company_payments_cards', { company_id : this.$route.params.id } );
              this.$router.go ();
            }
          } )
          .catch ( error => {
            this.$store.commit ( 'custom/toggle_loader', false );
            if ( error.response.data.errors ) {
              this.$root.$emit("snack_bar", {'show' : true, 'message' : Object.values ( error.response.data.errors )[ 0 ][ 0 ], 'snackbarColor' : 'red'});
            } else {
              this.$root.$emit("snack_bar", {'show' : true, 'message' : "Something want wrong Please try again!", 'snackbarColor' : 'red'});
            }
          } )
    }
  }
}
