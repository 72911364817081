<!-- System: STA
    Purpose: This compoment will display Billing
            information, details etc.
-->
<template>
  <v-app>
    <div class="billing-main-div">
      <div class="mt-3 ml-auto">
        <!-- Billing Information Component Render If No Billing Details Found -->
        <BillingInfo v-if="!check_subscription || showUpdateForm" />
        <!-- Render If Payment Method is Stripe -->
        <v-container
          class="container-bg"
          elevation-5
          v-if="check_subscription && !showUpdateForm && !PayPal"
        >
          <v-row class="d-flex justify-space-between d-flex align-center mx-2">
            <!-- Plan Details -->
            <v-col cols="12" md="4" v-if="selected_company.subscription_plan" class="mt-5 mx-auto">
              Your Selected Plan is:
              <span>
                <v-chip class="ma-2 text-capitalize" color="blue" text-color="white">
                  {{ selected_company.subscription_plan.replace(/-/g," ") }}
                </v-chip>
              </span>
            </v-col>
            <v-col cols="12" md="4" v-else class="my-5 mx-auto"></v-col>
            <!-- Update Plan Button -->
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8" class="d-flex justify-end pa-0">
              <v-row dense class="d-flex justify-end">
                <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="pa-0">
                  <v-btn
                @click="updateplan"
                color="success"
                class="float-right"
                :class="$vuetify.breakpoint.xs ? '' : 'mt-5'"
                v-if="company_payments_cards.length >= 1"
                >Update Plan</v-btn
              >
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="pa-0 ml-n6">
                  <!-- Resume Subscription Button -->
              <v-btn
                @click="resumeSubscription"
                class="float-right"
                :class="$vuetify.breakpoint.xs ? 'my-2' : 'mt-5'"
                v-if="
                  company_payments_cards.length >= 1 &&
                  (selected_company.subscription_canceled == 'canceled' ||
                    selected_company.onGracePeriod)
                "
                color="primary"
                >Resume Subscription
              </v-btn>
              <!-- Cancel Subscription Button -->
              <v-btn
                v-else
                @click="cancelSubscription"
                class="float-right"
                :class="$vuetify.breakpoint.xs ? 'my-2' : 'mt-5'"
                color="error"
                >Cancel Subscription
              </v-btn>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="pa-0" v-if="
                  company_payments_cards.length >= 1 &&
                  (selected_company.subscription_canceled == 'canceled' ||
                    selected_company.onGracePeriod)
                ">
                  <!-- Cancel & Remove Cards Button -->
              <v-btn
                @click="removeSubscriptionCards"
                class="float-right"
                :class="$vuetify.breakpoint.xs ? 'my-2' : 'mt-5'"
                
                color="red"
                >Cancel & Remove Cards
              </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <!-- Credit Card Area -->
        <v-container
          class="container-bg my-payment-methods mt-10"
          v-if="check_subscription && !showUpdateForm && !PayPal"
          elevation-5
        >
          <h3 class="card-title">Payment Method</h3>
          <p class="card-subtitle mt-5">
            The first card that you add will become the default card and cannot
            be deleted. You have to enter cards separately for all the companies
            that you create.
          </p>
          <v-row
            class="row payment-methods ml-0 mr-0 justify-content-between mt-10"
          >
            <credit-card
              :billingCards="company_payments_cards"
              @makeDefault="makeDefault"
              @removeCard="removeCard"
            />
          </v-row>
          <!-- Stripe Invoice Histroy Table Component  -->
          <StripeInvoiceHistory />
        </v-container>
        <!-- If Payment Method Is Paypal Then Paypal Invoice Table Component Render -->
        <v-container>
          <div
            v-if="
              check_subscription &&
              checkPayPalGateway &&
              !showUpdateForm &&
              PayPal
            "
          >
            <PaypalInvoiceHistroy />
          </div>
        </v-container>
        <!-- Add Credit Card Component -->
        <CreditCardModel @pushToCard="addToCards" />
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import PaymentMethodMixin from "@/components/Billing/PaymentMethodMixin";
export default {
  name: "Billing",
  components: {
    CreditCard: () => import("@/components/Billing/CreditCard"),
    StripeInvoiceHistory: () =>
      import("@/components/Billing/StripeInvoiceHistroy"),
    CreditCardModel: () => import("@/components/Billing/CreditCardModal"),
    BillingInfo: () => import("@/components/Billing/BillingInfo"),
    PaypalInvoiceHistroy: () =>
      import("@/components/Billing/PaypalInvoiceHistroy"),
  },
  data() {
    return {
      PayPal: false,
      stripe_plan: "",
      stripe_product: "",
      status: true,
      showUpdateForm: false,
      cards_array: [],
      cards: [],
      option: {
        plan: null,
        product: null,
      },
    };
  },
  mixins: [PaymentMethodMixin],
  computed: {
    ...mapState("custom", ["companies_list", "company_payments_cards"]),
    /**
     * Return the trial status
     */
    check_trial() {
      return this.$store.state.custom.check_trial_period_status;
    },
    /**
     * Return the subscription status
     */
    check_subscription() {
      return this.$store.state.custom.company_subscription_status;
    },
    /**
     * Return the payment gateway
     */
    checkPayPalGateway() {
      for (let i = 0; i < this.companies_list.length; i++) {
        if (this.companies_list[i].id == this.$route.params.id) {
          if (this.companies_list[i].payment_gateway == "paypal") {
            this.PayPal = true;
            return true;
          } else {
            return false;
          }
        }
      }
    },

    check_paypal_id() {
      return this.$store.state.custom.paypal_plan_id;
    },

    selected_company() {
      return this.companies_list.find((c) => c.id === this.$route.params.id);
    },
  },
  mounted() {
    let route = this.$route.query;
    this.get_company_payments_cards();
  },
  methods: {
    /**
     * This function is resposible for updating
     * the plan
     */
    updateplan() {
      this.showUpdateForm = true;
    },
    addToCards(card) {
      this.cards.push(card);
      this.cards_array.push(card);
    },
    /**
     * This function is resposible for
     * making the card default
     */
    makeDefault(index) {
      this.cards.find((card) => card.isDefault === true).isDefault = false;
      this.cards.findIndex(index).isDefault = true;
    },
    /**
     * This function is resposible for
     * removing the card
     */
    removeCard(index) {
      this.cards.splice(index, 1);
    },
    /**
     * This function is resposible for
     * cancelling the subscription
     */
    cancelSubscription() {
      if (
        confirm(
          "Do you really cancel your subscription? We really don't want you to leave please " +
            "note you can still resume your account until your current payment expires."
        )
      ) {
        let company_id = this.$route.params.id;
        this.$store.dispatch("custom/company_cancel_subscription", {
          company_id,
        });
      }
    },
    /**
     * This function is resposible for
     * resuming the subscription
     */
    resumeSubscription() {
      if (confirm("Are you sure to resume your subscription")) {
        let company_id = this.$route.params.id;
        this.$store.dispatch("custom/company_resume_subscription", {
          company_id,
        });
      }
    },
    /**
     * This function is resposible for
     * removing the subscription and cards
     */
    removeSubscriptionCards() {
      if (
        confirm(
          "Are you sure to remove your subscription & any associated payment cards instantly?"
        )
      ) {
        let company_id = this.$route.params.id;
        this.$store.dispatch("custom/company_remove_subscription", {
          company_id,
        });
      }
    },
    get_company_payments_cards() {
      let company_id = this.$route.params.id;
      this.$store.dispatch("custom/company_payments_cards", { company_id });
    },
  },
};
</script>

<style scoped>
.container-bg {
  background: white;
}
</style>
