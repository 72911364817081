var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('div',{staticClass:"billing-main-div"},[_c('div',{staticClass:"mt-3 ml-auto"},[(!_vm.check_subscription || _vm.showUpdateForm)?_c('BillingInfo'):_vm._e(),(_vm.check_subscription && !_vm.showUpdateForm && !_vm.PayPal)?_c('v-container',{staticClass:"container-bg",attrs:{"elevation-5":""}},[_c('v-row',{staticClass:"d-flex justify-space-between d-flex align-center mx-2"},[(_vm.selected_company.subscription_plan)?_c('v-col',{staticClass:"mt-5 mx-auto",attrs:{"cols":"12","md":"4"}},[_vm._v(" Your Selected Plan is: "),_c('span',[_c('v-chip',{staticClass:"ma-2 text-capitalize",attrs:{"color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.selected_company.subscription_plan.replace(/-/g," "))+" ")])],1)]):_c('v-col',{staticClass:"my-5 mx-auto",attrs:{"cols":"12","md":"4"}}),_c('v-col',{staticClass:"d-flex justify-end pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 8}},[_c('v-row',{staticClass:"d-flex justify-end",attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 4}},[(_vm.company_payments_cards.length >= 1)?_c('v-btn',{staticClass:"float-right",class:_vm.$vuetify.breakpoint.xs ? '' : 'mt-5',attrs:{"color":"success"},on:{"click":_vm.updateplan}},[_vm._v("Update Plan")]):_vm._e()],1),_c('v-col',{staticClass:"pa-0 ml-n6",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 4}},[(
                _vm.company_payments_cards.length >= 1 &&
                (_vm.selected_company.subscription_canceled == 'canceled' ||
                  _vm.selected_company.onGracePeriod)
              )?_c('v-btn',{staticClass:"float-right",class:_vm.$vuetify.breakpoint.xs ? 'my-2' : 'mt-5',attrs:{"color":"primary"},on:{"click":_vm.resumeSubscription}},[_vm._v("Resume Subscription ")]):_c('v-btn',{staticClass:"float-right",class:_vm.$vuetify.breakpoint.xs ? 'my-2' : 'mt-5',attrs:{"color":"error"},on:{"click":_vm.cancelSubscription}},[_vm._v("Cancel Subscription ")])],1),(
                _vm.company_payments_cards.length >= 1 &&
                (_vm.selected_company.subscription_canceled == 'canceled' ||
                  _vm.selected_company.onGracePeriod)
              )?_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 4}},[_c('v-btn',{staticClass:"float-right",class:_vm.$vuetify.breakpoint.xs ? 'my-2' : 'mt-5',attrs:{"color":"red"},on:{"click":_vm.removeSubscriptionCards}},[_vm._v("Cancel & Remove Cards ")])],1):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.check_subscription && !_vm.showUpdateForm && !_vm.PayPal)?_c('v-container',{staticClass:"container-bg my-payment-methods mt-10",attrs:{"elevation-5":""}},[_c('h3',{staticClass:"card-title"},[_vm._v("Payment Method")]),_c('p',{staticClass:"card-subtitle mt-5"},[_vm._v(" The first card that you add will become the default card and cannot be deleted. You have to enter cards separately for all the companies that you create. ")]),_c('v-row',{staticClass:"row payment-methods ml-0 mr-0 justify-content-between mt-10"},[_c('credit-card',{attrs:{"billingCards":_vm.company_payments_cards},on:{"makeDefault":_vm.makeDefault,"removeCard":_vm.removeCard}})],1),_c('StripeInvoiceHistory')],1):_vm._e(),_c('v-container',[(
            _vm.check_subscription &&
            _vm.checkPayPalGateway &&
            !_vm.showUpdateForm &&
            _vm.PayPal
          )?_c('div',[_c('PaypalInvoiceHistroy')],1):_vm._e()]),_c('CreditCardModel',{on:{"pushToCard":_vm.addToCards}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }