export default [
    { text : 'Select Country', value : '', disabled : true },
    { text : "Afghanistan", value : "AF", disabled : false },
    { text : "land Islands", value : "AX", disabled : false },
    { text : "Albania", value : "AL", disabled : false },
    { text : "Algeria", value : "DZ", disabled : false },
    { text : "American Samoa", value : "AS", disabled : false },
    { text : "AndorrA", value : "AD", disabled : false },
    { text : "Angola", value : "AO", disabled : false },
    { text : "Anguilla", value : "AI", disabled : false },
    { text : "Antarctica", value : "AQ", disabled : false },
    { text : "Antigua and Barbuda", value : "AG", disabled : false },
    { text : "Argentina", value : "AR", disabled : false },
    { text : "Armenia", value : "AM", disabled : false },
    { text : "Aruba", value : "AW", disabled : false },
    { text : "Australia", value : "AU", disabled : false },
    { text : "Austria", value : "AT", disabled : false },
    { text : "Azerbaijan", value : "AZ", disabled : false },
    { text : "Bahamas", value : "BS", disabled : false },
    { text : "Bahrain", value : "BH", disabled : false },
    { text : "Bangladesh", value : "BD", disabled : false },
    { text : "Barbados", value : "BB", disabled : false },
    { text : "Belarus", value : "BY", disabled : false },
    { text : "Belgium", value : "BE", disabled : false },
    { text : "Belize", value : "BZ", disabled : false },
    { text : "Benin", value : "BJ", disabled : false },
    { text : "Bermuda", value : "BM", disabled : false },
    { text : "Bhutan", value : "BT", disabled : false },
    { text : "Bolivia", value : "BO", disabled : false },
    { text : "Bosnia and Herzegovina", value : "BA", disabled : false },
    { text : "Botswana", value : "BW", disabled : false },
    { text : "Bouvet Island", value : "BV", disabled : false },
    { text : "Brazil", value : "BR", disabled : false },
    { text : "British Indian Ocean Territory", value : "IO", disabled : false },
    { text : "Brunei Darussalam", value : "BN", disabled : false },
    { text : "Bulgaria", value : "BG", disabled : false },
    { text : "Burkina Faso", value : "BF", disabled : false },
    { text : "Burundi", value : "BI", disabled : false },
    { text : "Cambodia", value : "KH", disabled : false },
    { text : "Cameroon", value : "CM", disabled : false },
    { text : "Canada", value : "CA", disabled : false },
    { text : "Cape Verde", value : "CV", disabled : false },
    { text : "Cayman Islands", value : "KY", disabled : false },
    { text : "Central African Republic", value : "CF", disabled : false },
    { text : "Chad", value : "TD", disabled : false },
    { text : "Chile", value : "CL", disabled : false },
    { text : "China", value : "CN", disabled : false },
    { text : "Christmas Island", value : "CX", disabled : false },
    { text : "Cocos (Keeling) Islands", value : "CC", disabled : false },
    { text : "Colombia", value : "CO", disabled : false },
    { text : "Comoros", value : "KM", disabled : false },
    { text : "Congo", value : "CG", disabled : false },
    { text : "Congo, The Democratic Republic of the", value : "CD", disabled : false },
    { text : "Cook Islands", value : "CK", disabled : false },
    { text : "Costa Rica", value : "CR", disabled : false },
    { text : "Cote D'Ivoire", value : "CI", disabled : false },
    { text : "Croatia", value : "HR", disabled : false },
    { text : "Cuba", value : "CU", disabled : false },
    { text : "Cyprus", value : "CY", disabled : false },
    { text : "Czech Republic", value : "CZ", disabled : false },
    { text : "Denmark", value : "DK", disabled : false },
    { text : "Djibouti", value : "DJ", disabled : false },
    { text : "Dominica", value : "DM", disabled : false },
    { text : "Dominican Republic", value : "DO", disabled : false },
    { text : "Ecuador", value : "EC", disabled : false },
    { text : "Egypt", value : "EG", disabled : false },
    { text : "El Salvador", value : "SV", disabled : false },
    { text : "Equatorial Guinea", value : "GQ", disabled : false },
    { text : "Eritrea", value : "ER", disabled : false },
    { text : "Estonia", value : "EE", disabled : false },
    { text : "Ethiopia", value : "ET", disabled : false },
    { text : "Falkland Islands (Malvinas)", value : "FK", disabled : false },
    { text : "Faroe Islands", value : "FO", disabled : false },
    { text : "Fiji", value : "FJ", disabled : false },
    { text : "Finland", value : "FI", disabled : false },
    { text : "France", value : "FR", disabled : false },
    { text : "French Guiana", value : "GF", disabled : false },
    { text : "French Polynesia", value : "PF", disabled : false },
    { text : "French Southern Territories", value : "TF", disabled : false },
    { text : "Gabon", value : "GA", disabled : false },
    { text : "Gambia", value : "GM", disabled : false },
    { text : "Georgia", value : "GE", disabled : false },
    { text : "Germany", value : "DE", disabled : false },
    { text : "Ghana", value : "GH", disabled : false },
    { text : "Gibraltar", value : "GI", disabled : false },
    { text : "Greece", value : "GR", disabled : false },
    { text : "Greenland", value : "GL", disabled : false },
    { text : "Grenada", value : "GD", disabled : false },
    { text : "Guadeloupe", value : "GP", disabled : false },
    { text : "Guam", value : "GU", disabled : false },
    { text : "Guatemala", value : "GT", disabled : false },
    { text : "Guernsey", value : "GG", disabled : false },
    { text : "Guinea", value : "GN", disabled : false },
    { text : "Guinea-Bissau", value : "GW", disabled : false },
    { text : "Guyana", value : "GY", disabled : false },
    { text : "Haiti", value : "HT", disabled : false },
    { text : "Heard Island and Mcdonald Islands", value : "HM", disabled : false },
    { text : "Holy See (Vatican City State)", value : "VA", disabled : false },
    { text : "Honduras", value : "HN", disabled : false },
    { text : "Hong Kong", value : "HK", disabled : false },
    { text : "Hungary", value : "HU", disabled : false },
    { text : "Iceland", value : "IS", disabled : false },
    { text : "India", value : "IN", disabled : false },
    { text : "Indonesia", value : "ID", disabled : false },
    { text : "Iran, Islamic Republic Of", value : "IR", disabled : false },
    { text : "Iraq", value : "IQ", disabled : false },
    { text : "Ireland", value : "IE", disabled : false },
    { text : "Isle of Man", value : "IM", disabled : false },
    { text : "Israel", value : "IL", disabled : false },
    { text : "Italy", value : "IT", disabled : false },
    { text : "Jamaica", value : "JM", disabled : false },
    { text : "Japan", value : "JP", disabled : false },
    { text : "Jersey", value : "JE", disabled : false },
    { text : "Jordan", value : "JO", disabled : false },
    { text : "Kazakhstan", value : "KZ", disabled : false },
    { text : "Kenya", value : "KE", disabled : false },
    { text : "Kiribati", value : "KI", disabled : false },
    { text : "Korea, Democratic People'S Republic of", value : "KP", disabled : false },
    { text : "Korea, Republic of", value : "KR", disabled : false },
    { text : "Kuwait", value : "KW", disabled : false },
    { text : "Kyrgyzstan", value : "KG", disabled : false },
    { text : "Lao People'S Democratic Republic", value : "LA", disabled : false },
    { text : "Latvia", value : "LV", disabled : false },
    { text : "Lebanon", value : "LB", disabled : false },
    { text : "Lesotho", value : "LS", disabled : false },
    { text : "Liberia", value : "LR", disabled : false },
    { text : "Libyan Arab Jamahiriya", value : "LY", disabled : false },
    { text : "Liechtenstein", value : "LI", disabled : false },
    { text : "Lithuania", value : "LT", disabled : false },
    { text : "Luxembourg", value : "LU", disabled : false },
    { text : "Macao", value : "MO", disabled : false },
    { text : "Macedonia, The Former Yugoslav Republic of", value : "MK", disabled : false },
    { text : "Madagascar", value : "MG", disabled : false },
    { text : "Malawi", value : "MW", disabled : false },
    { text : "Malaysia", value : "MY", disabled : false },
    { text : "Maldives", value : "MV", disabled : false },
    { text : "Mali", value : "ML", disabled : false },
    { text : "Malta", value : "MT", disabled : false },
    { text : "Marshall Islands", value : "MH", disabled : false },
    { text : "Martinique", value : "MQ", disabled : false },
    { text : "Mauritania", value : "MR", disabled : false },
    { text : "Mauritius", value : "MU", disabled : false },
    { text : "Mayotte", value : "YT", disabled : false },
    { text : "Mexico", value : "MX", disabled : false },
    { text : "Micronesia, Federated States of", value : "FM", disabled : false },
    { text : "Moldova, Republic of", value : "MD", disabled : false },
    { text : "Monaco", value : "MC", disabled : false },
    { text : "Mongolia", value : "MN", disabled : false },
    { text : "Montenegro", value : "ME", disabled : false },
    { text : "Montserrat", value : "MS", disabled : false },
    { text : "Morocco", value : "MA", disabled : false },
    { text : "Mozambique", value : "MZ", disabled : false },
    { text : "Myanmar", value : "MM", disabled : false },
    { text : "Namibia", value : "NA", disabled : false },
    { text : "Nauru", value : "NR", disabled : false },
    { text : "Nepal", value : "NP", disabled : false },
    { text : "Netherlands", value : "NL", disabled : false },
    { text : "Netherlands Antilles", value : "AN", disabled : false },
    { text : "New Caledonia", value : "NC", disabled : false },
    { text : "New Zealand", value : "NZ", disabled : false },
    { text : "Nicaragua", value : "NI", disabled : false },
    { text : "Niger", value : "NE", disabled : false },
    { text : "Nigeria", value : "NG", disabled : false },
    { text : "Niue", value : "NU", disabled : false },
    { text : "Norfolk Island", value : "NF", disabled : false },
    { text : "Northern Mariana Islands", value : "MP", disabled : false },
    { text : "Norway", value : "NO", disabled : false },
    { text : "Oman", value : "OM", disabled : false },
    { text : "Pakistan", value : "PK", disabled : false },
    { text : "Palau", value : "PW", disabled : false },
    { text : "Palestinian Territory, Occupied", value : "PS", disabled : false },
    { text : "Panama", value : "PA", disabled : false },
    { text : "Papua New Guinea", value : "PG", disabled : false },
    { text : "Paraguay", value : "PY", disabled : false },
    { text : "Peru", value : "PE", disabled : false },
    { text : "Philippines", value : "PH", disabled : false },
    { text : "Pitcairn", value : "PN", disabled : false },
    { text : "Poland", value : "PL", disabled : false },
    { text : "Portugal", value : "PT", disabled : false },
    { text : "Puerto Rico", value : "PR", disabled : false },
    { text : "Qatar", value : "QA", disabled : false },
    { text : "Reunion", value : "RE", disabled : false },
    { text : "Romania", value : "RO", disabled : false },
    { text : "Russian Federation", value : "RU", disabled : false },
    { text : "RWANDA", value : "RW", disabled : false },
    { text : "Saint Helena", value : "SH", disabled : false },
    { text : "Saint Kitts and Nevis", value : "KN", disabled : false },
    { text : "Saint Lucia", value : "LC", disabled : false },
    { text : "Saint Pierre and Miquelon", value : "PM", disabled : false },
    { text : "Saint Vincent and the Grenadines", value : "VC", disabled : false },
    { text : "Samoa", value : "WS", disabled : false },
    { text : "San Marino", value : "SM", disabled : false },
    { text : "Sao Tome and Principe", value : "ST", disabled : false },
    { text : "Saudi Arabia", value : "SA", disabled : false },
    { text : "Senegal", value : "SN", disabled : false },
    { text : "Serbia", value : "RS", disabled : false },
    { text : "Seychelles", value : "SC", disabled : false },
    { text : "Sierra Leone", value : "SL", disabled : false },
    { text : "Singapore", value : "SG", disabled : false },
    { text : "Slovakia", value : "SK", disabled : false },
    { text : "Slovenia", value : "SI", disabled : false },
    { text : "Solomon Islands", value : "SB", disabled : false },
    { text : "Somalia", value : "SO", disabled : false },
    { text : "South Africa", value : "ZA", disabled : false },
    { text : "South Georgia and the South Sandwich Islands", value : "GS", disabled : false },
    { text : "Spain", value : "ES", disabled : false },
    { text : "Sri Lanka", value : "LK", disabled : false },
    { text : "Sudan", value : "SD", disabled : false },
    { text : "Suritext", value : "SR", disabled : false },
    { text : "Svalbard and Jan Mayen", value : "SJ", disabled : false },
    { text : "Swaziland", value : "SZ", disabled : false },
    { text : "Sweden", value : "SE", disabled : false },
    { text : "Switzerland", value : "CH", disabled : false },
    { text : "Syrian Arab Republic", value : "SY", disabled : false },
    { text : "Taiwan, Province of China", value : "TW", disabled : false },
    { text : "Tajikistan", value : "TJ", disabled : false },
    { text : "Tanzania, United Republic of", value : "TZ", disabled : false },
    { text : "Thailand", value : "TH", disabled : false },
    { text : "Timor-Leste", value : "TL", disabled : false },
    { text : "Togo", value : "TG", disabled : false },
    { text : "Tokelau", value : "TK", disabled : false },
    { text : "Tonga", value : "TO", disabled : false },
    { text : "Trinidad and Tobago", value : "TT", disabled : false },
    { text : "Tunisia", value : "TN", disabled : false },
    { text : "Turkey", value : "TR", disabled : false },
    { text : "Turkmenistan", value : "TM", disabled : false },
    { text : "Turks and Caicos Islands", value : "TC", disabled : false },
    { text : "Tuvalu", value : "TV", disabled : false },
    { text : "Uganda", value : "UG", disabled : false },
    { text : "Ukraine", value : "UA", disabled : false },
    { text : "United Arab Emirates", value : "AE", disabled : false },
    { text : "United Kingdom", value : "GB", disabled : false },
    { text : "United States", value : "US", disabled : false },
    { text : "United States Minor Outlying Islands", value : "UM", disabled : false },
    { text : "Uruguay", value : "UY", disabled : false },
    { text : "Uzbekistan", value : "UZ", disabled : false },
    { text : "Vanuatu", value : "VU", disabled : false },
    { text : "Venezuela", value : "VE", disabled : false },
    { text : "Viet Nam", value : "VN", disabled : false },
    { text : "Virgin Islands, British", value : "VG", disabled : false },
    { text : "Virgin Islands, U.S.", value : "VI", disabled : false },
    { text : "Wallis and Futuna", value : "WF", disabled : false },
    { text : "Western Sahara", value : "EH", disabled : false },
    { text : "Yemen", value : "YE", disabled : false },
    { text : "Zambia", value : "ZM", disabled : false },
    { text : "Zimbabwe", value : "ZW", disabled : false }
  ];
  